import { Link } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
import companyLogoTrans from './logo_trans.svg';
import companyLogo from './logo.svg';
import companyLogoMargin from './logo_margin.svg';
const BI = () => {
    return (
        <>
            <Element name="bi-main">
                <div className="h-screen justify-center items-center bg-white flex">
                    <div className="text-center">
                        <div className="flex justify-center items-center mb-4">
                            <img src={companyLogoTrans} alt="Company Logo" className="h-20 mr-2" />
                            <span className="text-6xl font-bold text-primary ml-2">BI</span>
                        </div>
                        <p className="text-2xl text-gray-500 mb-8">갓잉의 아이덴티티를 소개합니다.</p>
                        <div className="space-x-4 mt-4">
                            <ScrollLink to="bi-philosophy" smooth={true} duration={500} className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">갓잉의 철학</ScrollLink>
                            <ScrollLink to="bi-color" smooth={true} duration={500} className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">색상</ScrollLink>
                            <ScrollLink to="bi-logo" smooth={true} duration={500} className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">로고</ScrollLink>
                        </div>
                    </div>
                </div>
            </Element>
            <Element name="bi-philosophy" className="p-4 bg-ultralightmain">
                <div className="h-screen justify-center items-center flex">
                    <div className="text-center flex flex-col items-center">
                        <img src={companyLogoTrans} alt="Company Logo" className="h-20 mb-4" />
                        <h1 className="text-6xl font-bold text-primary mb-4">Philosophy</h1>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">갓잉은 공유 중심의 공부 계획 및 기록 플랫폼입니다.</p>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">갓잉은 고등학생 3명이 창업한 스타트업으로, 누구보다 학생들의 니즈에 공감할 수 있습니다.</p>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">다른 친구의 공부법을 참고한 후 성적이 오른 경험을 바탕으로</p>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">더 많은 사람들이 공부에 대한 피드백을 주고받을 수 있는 플랫폼을 만들고자 합니다.</p>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">갓잉은 공부법을 공유하고, 공부한 내용을 기록하며,</p>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">서로에게 긍정적인 피드백을 주고받을 수 있는 플랫폼입니다.</p>
                        <Link to="/about" className="text-primary text-xl mt-4"><i className="bi bi-info-circle mr-2"></i>더 알아보기</Link>
                        <ScrollLink to="bi-color" smooth={true} duration={500} className="text-primary text-xl mt-16">
                            <div className="flex flex-col items-center">
                                <div>색상</div>
                                <div><i className="bi bi-chevron-down"></i></div>
                            </div>
                        </ScrollLink>
                    </div>
                </div>
            </Element>
            <Element name="bi-color" className="p-4">
                <div className="h-screen justify-center items-center flex">
                    <div className="text-center flex flex-col items-center">
                        <img src={companyLogoTrans} alt="Company Logo" className="h-20 mb-4 mt-16" />
                        <h1 className="text-6xl font-bold text-primary mb-4">Brand Color</h1>
                        <p className="text-sm md:text-xl text-gray-700 mb-8">갓잉은 집중력에 도움이 된다고 알려진 파란색 계열의 색상을 메인 색상으로 사용 중입니다.</p>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col items-end mr-2">
                                <div className="bg-primary h-32 w-32 rounded-full flex justify-center items-center shadow-xl hover:shadow-2xl transition duration-100">
                                    <span className="text-white text-xl font-bold">Primary</span>
                                </div>
                            </div>
                            <div className="flex justify-center items-center h-32">
                                <div className="text-primary text-left align-middle flex-col items-center">
                                    <p className="text-xl font-bold">Primary</p>
                                    <p className="text-xl font-bold">#2f54d2</p>
                                    <p className="text-lg text-gray-500">R: 47, G: 84, B: 210</p>
                                </div>
                            </div>

                            <div className="flex flex-col items-end mr-2">
                                <div className="bg-secondary h-32 w-32 rounded-full flex justify-center items-center shadow-xl hover:shadow-2xl transition duration-100">
                                    <span className="text-white text-xl font-bold">Secondary</span>
                                </div>
                            </div>
                            <div className="flex justify-center items-center h-32">
                                <div className="text-primary text-left align-middle flex-col items-center">
                                    <p className="text-xl font-bold">Secondary</p>
                                    <p className="text-xl font-bold">#748de1</p>
                                    <p className="text-lg text-gray-500">R: 116, G: 141, B: 225</p>
                                </div>
                            </div>
                        </div>
                        <ScrollLink to="bi-logo" smooth={true} duration={500} className="text-primary text-xl mt-16">
                            <div className="flex flex-col items-center">
                                <div>로고</div>
                                <div><i className="bi bi-chevron-down"></i></div>
                            </div>
                        </ScrollLink>
                    </div>
                </div>
            </Element>
            <Element name="bi-logo" className="p-4">
                <div className="h-screen justify-center items-center bg-white flex">
                    <div className="text-center flex flex-col items-center">
                        <h1 className="text-6xl font-bold text-primary mb-8">Logo</h1>
                        <div className="grid grid-cols-2 gap-8">
                            <div className="flex flex-col items-center">
                                <img src={companyLogoMargin} alt="Company Logo" className="h-32" />
                                <p className="text-xl font-semibold text-primary mt-4">Logo</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <img src={companyLogo} alt="Company Color" className="h-32 shadow-xl hover:shadow-2xl transition duration-100 rounded-3xl" />
                                <p className="text-xl font-semibold text-primary mt-4">App Icon</p>
                            </div>
                        </div>
                        <p className="text-sm md:text-xl text-gray-700 mt-8 mb-2">갓잉 로고의 아랫부분은 펼쳐진 책의 모양과 더불어 공부 시간을 의미하는 시침과 분침을 형상화하였으며,</p>
                        <p className="text-sm md:text-xl text-gray-700 mb-2">전반적인 로고는 사람 두 명의 모습으로 공유를 중시하는 갓잉의 가치를 담고 있습니다.</p>
                    </div>
                </div>
            </Element>
        </>
    );
}

export default BI;